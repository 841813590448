document.addEventListener('DOMContentLoaded', function() {
    let menuButton = document.getElementById('mobile-menu-button');
    let menu = document.getElementById('mobile-menu');

    menuButton.onclick = function(event) {
        menu.classList.toggle('opening');
    }
});

var scrollTop = function() {
    if ('scrollingElement' in document) {
        return document.scrollingElement.scrollTop;
    }
    return window.pageYOffset;
};

document.addEventListener('scroll', function(e) {
    let drone = document.getElementById('drone');

    //drone.classList.toggle("scroll-top-btn", scrollTop() > 100);
    if (scrollTop() > 100) {
        drone.classList.add("scroll-top-btn");
    } else {
        drone.classList.remove("scroll-top-btn");
    }
});

var parallaxTargets = [
    {trigger: 'about', target: 'inter-section-1-img'},
    {trigger: 'plan', target: 'inter-section-2-img'},
    {trigger: 'spot', target: 'inter-section-3-img'},
    {trigger: 'staff', target: 'inter-section-4-img'},
    {trigger: 'reserve', target: 'inter-section-5-img'},
];

document.addEventListener('scroll', function(e) {
    var scrollY = window.pageYOffset;

    var visibleImage = function(trigger, target) {
        var triggerElm = document.getElementById(trigger);
        var targetElm = document.getElementById(target);
    
        if(!triggerElm || !targetElm) return;
    
        var triggerRect = triggerElm.getBoundingClientRect();
        var triggerY = scrollY + triggerRect.top;
        if(scrollY > triggerY) {
            targetElm.classList.add('inter-section-visible');
        } else {
            targetElm.classList.remove('inter-section-visible');
        }
    }
    for (const elm of parallaxTargets) {
        visibleImage(elm.trigger, elm.target);
    }
});

var header = document.getElementById("header");
header.style.position = 'relative'; // for IE11

var objectFitImages = require('object-fit-images');
objectFitImages();